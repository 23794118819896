/** Used to define container behavior: width, position: fixed etc... **/
.Toastify__toast-container {
}

/** Used to define the position of the ToastContainer **/
.Toastify__toast-container--top-left {
}
.Toastify__toast-container--top-center {
}
.Toastify__toast-container--top-right {
}
.Toastify__toast-container--bottom-left {
}
.Toastify__toast-container--bottom-center {
}
.Toastify__toast-container--bottom-right {
}

/** Classes for the displayed toast **/
.Toastify__toast {
  border-radius: 5px !important;
}
.Toastify__toast--rtl {
}
.Toastify__toast--dark {
  background: $primary !important;
}
.Toastify__toast--default {
}
.Toastify__toast--info {
  background: $info !important;
}
.Toastify__toast--success {
  background: $success !important;
}
.Toastify__toast--warning {
  background: $warning !important;
}
.Toastify__toast--error {
  background: $danger !important;
}
.Toastify__toast-body {
  font-family: "Biotif", sans-serif;
}

/** Classes for the close button. Better use your own closeButton **/
.Toastify__close-button {
}
.Toastify__close-button--default {
}
.Toastify__close-button > svg {
}
.Toastify__close-button:hover,
.Toastify__close-button:focus {
}

/** Classes for the progress bar **/
.Toastify__progress-bar {
}
.Toastify__progress-bar--animated {
}
.Toastify__progress-bar--controlled {
}
.Toastify__progress-bar--rtl {
}
.Toastify__progress-bar--default {
}
.Toastify__progress-bar--dark {
}
