@import "./../_mixins/responsive";

.account-email {
  margin: 0 auto;
  max-width: 100%;
  width: 600px;

  form {
    width: 100%;
  }
}

.account-selection {
  .type {
    padding: 20px;
    text-align: center;
    cursor: pointer;

    &.active {
      background-color: #333;
      color: #fff;
    }
  }

  &.types {
    .type {
      border-radius: 50%;
      height: 220px;
      width: 220px;
      display: flex;
      flex-direction: column;
      margin: 0;
      align-items: center;
      justify-content: center;
      transition: all 0.5s;
      padding: 0;

      strong {
        display: block;
        font-size: 18px;
      }

      p {
        font-size: 15px;
        margin-bottom: 0;
        margin-top: 10px;
      }

      &.active {
        background-color: inherit;
        color: #333;
        opacity: 1;
      }

      &:not(.active) {
        opacity: 0.4;

        &:hover {
          background-color: inherit;
          color: #333;
          opacity: 1;
        }
      }
    }

    @include respond-to(upto-sm) {
      flex-direction: column;

      .type {
        border-radius: 10px;
        width: 100%;
        height: auto;
        margin-bottom: 20px;
        display: block;
        padding: 20px 0;

        p {
          margin-top: 5px;
        }
      }
    }

    &.sign-up {
      .type {
        border: none;
        strong {
          display: block;
          font-size: 28px;
        }
      }
    }
  }

  &.experiences {
    .experience {
      text-align: center;
      cursor: pointer;
      font-size: 18px;

      &.active {
        font-weight: 600;
      }

      &:not(.active) {
        opacity: 0.4;
        &:hover {
          opacity: 1;
        }
      }

      @include respond-to(upto-sm) {
        font-size: 15px;
      }
    }
  }

  &.levels {
    .level {
      text-align: center;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      transition: all 0.5s;
      margin: 0;
      img {
        width: 100%;
        max-width: 150px;
        margin-bottom: 10px;
      }

      strong {
        display: block;
        font-size: 18px;
      }

      &.active {
        opacity: 1;
      }

      &:not(.active) {
        opacity: 0.4;
        &:hover {
          opacity: 1;
        }
      }
    }

    @include respond-to(upto-sm) {
      flex-direction: column;
      .level {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        text-align: left;
        border: 2px solid;
        border-radius: 10px;
        width: 100%;
        height: auto;
        margin-bottom: 20px;
        padding: 10px;

        img {
          width: 70px;
          margin-right: 20px;
        }
        p {
          margin: 0 !important;
        }

        &.active {
          background-color: inherit;
          border-color: #333;
          color: #333;
          opacity: 1;
        }

        &:not(.active) {
          border-color: #ddd;
          color: #777;
          opacity: 0.7;

          &:hover {
            background-color: inherit;
            border-color: #333;
            color: #333;
            opacity: 1;
          }
        }
      }
    }
  }
}

.account-profiling {
  @include respond-to(from-md) {
    min-height: 100vh;
    margin-bottom: -80px;
  }
  display: flex;

  .left {
    position: relative;
    display: flex;
    align-items: start;
    filter: grayscale(100%);
    padding-top: 40px;
    justify-content: center;
    width: 35%;
    transition: all 0.5s;
    height: 100vh;
    z-index: 99;
    background-position: center;
    background-size: cover;
    background-image: url("https://italian-artisan.com/wp-content/uploads/2020/03/What-do-you-want-to-produce-Italian-Artisan-1024x711.jpg");

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.6);
      width: 100%;
      height: 100%;
    }

    img {
      width: 250px;
      margin: 0 auto;
      position: relative;
    }

    .text {
      position: absolute;
      bottom: 40px;
      position: absolute;
      left: 0;
      padding: 0 20px;
      font-size: 24px;
      color: #fff;
    }
  }

  .right {
    width: 65%;
    // overflow: auto;
    padding: 0 20px;
    position: relative;

    .progress {
      margin: 0 -20px;
      border-radius: 0;
    }
  }

  @include respond-to(upto-sm) {
    display: block;
    height: initial;
    margin-bottom: 0;

    .left {
      display: none !important;
    }

    .left,
    .right {
      width: 100%;
    }

    .left {
      padding: 0;
      align-items: center;
      height: 100px !important;
      img {
        max-width: 95%;
        width: 300px;
        position: relative;
      }
      .text {
        display: none;
      }
    }

    .right {
      overflow: hidden;
      padding-top: 20px;
      padding-bottom: 20px;
      height: auto;

      .progress {
        margin-top: -20px;
        margin-bottom: 20px;
      }

      .step {
        height: auto;
      }

      .step-navigation {
        bottom: 0;
        width: calc(100% - 40px);
        position: fixed;

        &.relative {
          position: relative;
          width: 100%;
          bottom: initial;
        }
      }
    }
  }
}

.map-wrapper {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  border: 1px solid #333;
  border-radius: 10px;

  @include respond-to(upto-sm) {
    display: none;
  }
}
