@import "./../_mixins/responsive";

.right {
  .step {
    .header {
      display: flex;
      align-items: center;
      i {
        font-size: 30px;
        cursor: pointer;
        margin-right: 20px;
      }

      h3 {
        // margin: 0;
      }

      @include respond-to(from-md) {
        height: 140px;
      }

      @include respond-to(upto-sm) {
        margin-bottom: 40px;
      }
    }

    .content {
      @include respond-to(from-md) {
        height: 70vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .experience {
        img {
          width: 100%;
          max-width: 150px;
          margin-bottom: 10px;
        }
      }
    }
  }
}

.react-tel-input {
  .form-control {
    width: 100% !important;
    height: 38px !important;
    font-size: 15px !important;
    color: #000 !important;
  }
}

.PhoneInput {
  &.invalid {
    input {
      border-color: tomato !important;
      padding-right: calc(1.5em + 0.75rem);
      background-image: url('data:image/svg + xml,%3csvgxmlns="http://www.w3.org/2000/svg"width="12"height="12"fill="none"stroke="tomato"viewBox="0 0 12 12"%3e%3ccirclecx="6"cy="6"r="4.5"/%3e%3cpathstroke-linejoin="round"d="M5.8 3.6h.4L6 6.5z"/%3e%3ccirclecx="6"cy="8.2"r=".6"fill="tomato"stroke="none"/%3e%3c/svg%3e');
      background-repeat: no-repeat;
      background-position: right calc(0.375em + 0.1875rem) center;
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }
  }
  input {
    color: #495057;
    padding: 0.375rem 0.75rem;
    border: 1px solid #ced4da !important;
    height: 38px;
    border-radius: 0.25rem;
  }
  + .invalid-feedback {
    display: block !important;
  }
}
