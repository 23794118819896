@import "./../_mixins/responsive";

.footer-img {
  background-size: cover;
  background-position: center;
  position: relative;
  height: 300px;
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.65);
    width: 100%;
    height: 100%;
  }

  .container {
    color: #fff;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    height: 100%;
    grid-gap: 40px;
    > div {
      position: relative;
      padding: 0 0 20px;
      align-self: flex-end;

      p {
        font-size: 20px;
      }
    }
  }

  @include respond-to(upto-sm) {
    height: auto !important;
    padding-top: 40px;
    .container {
      grid-template-columns: 1fr;
    }
  }

  &.one-col {
    .container {
      grid-template-columns: 1fr;
    }
  }
}

footer#footer {
  margin-top: 80px;

  .main,
  .copy {
    .container {
      display: grid;
      grid-gap: 15px;

      @include respond-to(from-md) {
        grid-template-columns: repeat(5, 1fr);
      }

      @include respond-to(sm) {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }

  .main {
    padding: 60px 0;
    background-color: #111;
    color: #fff;
    font-size: 15px;

    .logo {
      img {
        max-width: 100%;
        width: 180px;
      }
    }

    .menu {
      > :first-child {
        font-size: 16px;
      }
      a {
        display: block;
        color: #fff;
        &:hover,
        &:active,
        &:focus {
          color: #f5f5f5;
        }
      }
    }
  }
  .copy {
    background-color: #333;
    padding: 20px 0;
    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    display: flex;

    a {
      font-size: 12px;
      color: #f5f5f5;
      &:hover,
      &:active,
      &:focus {
        color: #fff;
      }
    }

    @include respond-to(xs) {
      .container {
        flex-direction: column;
      }

      a {
        margin-bottom: 10px;
      }
    }
  }
}
