@import "./../_mixins/responsive";

header {

    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    position: sticky;
    top: 0;
    z-index: 100;
  
      .brand-logo {       
          > img {
              @include respond-to(upto-sm){
                width: 150px;
              }
          }
      }

    .brand-logo:hover {
      text-decoration: none !important;
    }
  
  }
  