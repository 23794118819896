/* make the customizations */
$theme-colors: (
  "primary": #333,
  "info": teal,
  "danger": tomato,
);

@import "~bootstrap/scss/bootstrap";

@import "./_partials/toast";
@import "./_partials/header";
@import "./_partials/footer";
@import "./_partials/wizard";
@import "./_partials/account";
@import "./_partials/typography";
@import "./_partials/profile";
@import "./_partials/brand-header";

.hidden-sm {
  @include respond-to(sm) {
    display: none !important;
  }
}

.hidden-md-up {
  @include respond-to(from-md) {
    display: none !important;
  }
}

.hidden-xs {
  @include respond-to(xs) {
    display: none !important;
  }
}

.form-group {
  height: 93px;
  margin-bottom: 0;
}

.invalid-feedback {
  font-size: 11px;
}

@include respond-to(xs) {
  p,
  strong,
  span {
    font-size: 14px;
  }
}

label {
  font-size: 14px;
  font-weight: 400;
  color: #777;
}

.form-text {
  font-size: 11px;
}

.App {
  @include respond-to(from-md) {
    min-height: 620px;
  }
}

.dropdown-menu {
  padding: 0;
}
.dropdown-item {
  padding-top: 10px;
  padding-bottom: 10px;
}

.show-password-btn {
  top: 50%;
  border: 0;
  right: 0.5rem;
  position: absolute;
  transform: translateY(-50%);
  background: unset;
  height: 1.875rem;

  &.show {
    padding-top: 0.25rem;
  }
}

iframe{
  display: none;
}

.bg-image {
  font-size: 14px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  color: white;
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.65);
    width: 100%;
    height: 100%;
  }

  .container {
    color: #fff;
    height: 100%;
    > div {
      position: relative;
      p {
        font-size: 20px;
      }
    }
  }
}

