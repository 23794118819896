.profile-grid {
  display: grid;
  grid-template-columns: 200px auto;
  padding-top: 30px;

  .sidebar {
    position: relative;
    border-right: 1px solid #eee;

    .edit {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }

    img {
      margin-bottom: 10px;
    }

    .menu {
      margin-top: 30px;
      ul {
        padding: 0;
        margin: 0 0 0 -15px;
        li {
          list-style: none;
          display: block;
          a {
            display: block;
            padding: 10px 15px;
            cursor: pointer;
            text-decoration: none !important;
          }

          &:hover {
            a {
              background-color: #ddd;
            }
          }
        }
      }
    }
  }
}
