@import "./../_mixins/responsive";

header {
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    .logo {
      width: 350px;
      text-align: center;
      max-width: 100%;
      margin: 0 auto;
    }
  }

  border-bottom: 1px solid #ddd;
  height: 100px;
  position: relative;

  a {
    font-size: 18px;
  }
  img {
    display: block;
    margin: 0 auto;
    width: 220px;
    position: relative;
  }

  .logo:hover {
    text-decoration: none !important;
  }

  @include respond-to(upto-sm) {
    height: 80px;
  }
}
