@mixin respond-to($media) {
  @if $media==xxs {
    @media screen and (max-width: 479px) {
      @content;
    }
  } @else if $media==xxs-l {
    @media screen and (min-width: 480px) and (max-width: 767px) {
      @content;
    }
  } @else if $media==from-xs {
    @media screen and (min-width: 480px) {
      @content;
    }
  } @else if $media==xs {
    @media screen and (max-width: 767px) {
      @content;
    }
  } @else if $media==sm {
    @media screen and (min-width: 768px) and (max-width: 991px) {
      @content;
    }
  } @else if $media==sm-and-md {
    @media screen and (min-width: 768px) and (max-width: 1199px) {
      @content;
    }
  } @else if $media==upto-sm {
    @media screen and (max-width: 991px) {
      @content;
    }
  } @else if $media==from-sm {
    @media screen and (min-width: 768px) {
      @content;
    }
  } @else if $media==md {
    @media screen and (min-width: 992px) and (max-width: 1199px) {
      @content;
    }
  } @else if $media==upto-md {
    @media screen and (max-width: 1199px) {
      @content;
    }
  } @else if $media==from-md {
    @media screen and (min-width: 992px) {
      @content;
    }
  } @else if $media==lg {
    @media screen and (min-width: 1200px) and (max-width: 1499px) {
      @content;
    }
  } @else if $media==from-lg {
    @media screen and (min-width: 1200px) {
      @content;
    }
  } @else if $media==xlg {
    @media screen and (min-width: 1500px) {
      @content;
    }
  }
}
