@import "./../fonts/biotif/stylesheet.css";

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Biotif", Arial, sans-serif;
}

.font-biotif {
  font-family: "Biotif", Arial, sans-serif !important;
}

a,
button {
  font-family: "Biotif", Arial, sans-serif !important;
}
